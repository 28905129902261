const Module = () => import("./Module.vue");
import store from "@/state/store";

const moduleRoute = {
  path: "/senasag",
  name: "senasag",
  component: Module,
  children: [
    {
      path: "",
      name: "senasag.default",
      meta: {
        title: "Inicio",
      },
      component: () => import("@/views/Default.vue"),
    },
    {
      path: "accesosia/solicitudes-sia",
      name: "solicitudes.sia",
      meta: {
        title: "Solicitudes SIA",
      },
      component: () => import("./views/acceso-sia/SolicitudesSia.vue"),
    },
    {
      path: "accesosia/solicitudes-de-cambio-contraña",
      name: "solicitudes.contrasena",
      meta: {
        title: "Solicitudes SIA",
      },
      component: () => import("./views/cambio-acceso/SolicitudesAcceso.vue"),
    },
    {
      path: "accesosia/solicitudes-sia/:uid",
      name: "solicitudessiadetalle",
      props: true,
      meta: {
        title: "Solicitudes SIA Detalle",
      },
      component: () => import("./views/acceso-sia/SolicitudesSiaDetalle.vue"),
    },
    {
      path: "actualizar/permiso/:id/",
      name: "actualizar-permiso",
      meta: {
        title: "Permiso",
      },
      component: () => import("./views/actualizar/Permiso.vue"),
    },
    {
      path: "actualizar/rol/:id/",
      name: "actualizar-rol",
      meta: {
        title: "Rol",
      },
      component: () => import("./views/actualizar/Rol.vue"),
    },

    {
      path: "crear/empresa",
      name: "senasag.crear.empresa",
      meta: {
        title: "Crear Empresa",
      },
      component: () => import("./views/registro-cuentas/Empresa.vue"),
    },

    {
      path: "crear/persona-natural",
      name: "senasag.crear.persona.natural",
      meta: {
        title: "Crear Persona Natural",
      },
      component: () => import("./views/registro-cuentas/PersonaNatural.vue"),
    },

    {
      path: "permisos",
      name: "permisos-permiso",
      meta: {
        title: "Ver permisos",
      },
      component: () => import("./views/Permiso.vue"),
    },
    {
      path: "profile",
      name: "senasag.profile",
      meta: {
        title: "Perfil",
      },
      component: () => import("./views/Profile.vue"),
    },
    {
      path: "registro/permiso",
      name: "registro-permiso",
      meta: {
        title: "Permiso",
      },
      component: () => import("./views/registros/Permiso.vue"),
    },
    {
      path: "registro/rol",
      name: "registro-rol",
      meta: {
        title: "Rol",
      },
      component: () => import("./views/registros/Rol.vue"),
    },
    {
      path: "roles",
      name: "roles-rol",
      meta: {
        title: "Ver roles",
      },
      component: () => import("./views/Roles.vue"),
    },
    {
      path: "sia/cambiocontrasena",
      name: "cambiocontrasena",
      meta: {
        title: "Solicitud de Servicios",
      },
      component: () => import("./views/SiaCambioContrasena"),
    },

    {
      path: "solicitudes/empresa/",
      name: "senasag.lista.solicitudes.empresas",
      meta: {
        title: "Listado Solicitudes Empresas",
      },
      component: () => import("./views/approve-requests/Company.vue"),
    },
    {
      path: "solicitudes/sucursal/",
      name: "senasag.lista.solicitudes.sucursales",
      meta: {
        title: "Listado Solicitudes Sucursales",
      },
      component: () => import("./views/approve-requests/Sucursales.vue"),
    },
    {
      path: "solicitudes/tramitador/",
      name: "senasag.lista.solicitudes.tramitador",
      meta: {
        title: "Listado Solicitudes Tramitadores",
      },
      component: () => import("./views/approve-requests/Tramitador.vue"),
    },
    {
      path: "solicitudes/tramitador-vincular/",
      name: "senasag.lista.solicitudes.tramitador-vincular",
      meta: {
        title: "Listado Solicitudes Vincular Tramitadore",
      },
      component: () =>
        import("./views/approve-requests/TramitadorVincular.vue"),
    },
    {
      path: "solicitudes/empresa/:id_solicitud_cuenta/",
      name: "senasag.detalle.solicitudes.empresas",
      meta: {
        title: "Detalle Solicitud Empresa",
      },
      component: () => import("./views/approve-requests/DetailedCompany.vue"),
    },
    //ACH 22/09/2024 ruta para crear sucursal lado usuarios
    {
      path: "solicitudes/empresa/crearsucursal/",
      name: "senasag.empresas.create_sucursal",
      meta: {
        title: "Crear Sucursal de empresa",
      },
      component: () => import("../empresa/views/CreateBranch.vue"),
    },
    {
      path: "solicitudes/sucursal/:id_sucursal/",
      name: "senasag.detalle.solicitudes.sucursales",
      meta: {
        title: "Detalle Solicitud Sucursal",
      },
      component: () => import("./views/approve-requests/DetalleSucursal.vue"),
    },
    {
      path: "solicitudes/tramitador/:id_solicitud_cuenta/",
      name: "senasag.detalle.solicitudes.tramitador",
      meta: {
        title: "Detalle Solicitud Tramitador",
      },
      component: () => import("./views/approve-requests/DetalleTramitador.vue"),
    },
    {
      path: "solicitudes/tramitador-vincular/:id_solicitud_vincular_tramitador/",
      name: "senasag.detalle.solicitudes.tramitador-vincular",
      meta: {
        title: "Detalle Solicitud Vincular Tramitador",
      },
      component: () =>
        import("./views/approve-requests/DetalleVincularTramitador.vue"),
    },
    {
      path: "tramites",
      name: "senasag.lista.tramites.llenados",
      meta: {
        title: "Trámites",
      },
      component: () => import("./views/TramitesAdmin.vue"),
    },
    {
      path: "firmar-documentos",
      name: "senasag.lista.firmar.documentos",
      meta: {
        title: "Firmar documentos",
      },
      component: () => import("./views/FirmarDocumentos.vue"),
    },
    {
      path: "firmar-certificados-sia",
      name: "senasag.lista.firmar.certificados.sia",
      meta: {
        title: "Firmar Certificados SIA",
      },
      component: () => import("./views/FirmarCertificadosSia.vue"),
    },
    {
      path: "tramites/pre-solicitados",
      name: "senasag.lista.solicitudes.tramite",
      meta: {
        title: "Trámites pre-solicitados",
      },
      component: () =>
        import("./views/approve-requests/TramitesPreSolicitados.vue"),
    },
    {
      path: "tramites/:id_solicitud_tramite",
      name: "senasag.detalle.tramites.llenados",
      meta: {
        title: "Trámites",
      },
      component: () =>
        import(
          "./views/plantilla-tramite/TramitePlantillaEditar/TramitePlantillaEditar.vue"
        ),
    },
    {
      path: "tramites/:id_solicitud_tramite/history-status",
      name: "senasag.detalle.tramites.history.status",
      meta: {
        title: "Historial de estados",
      },
      component: () => import("./views/StatusHistory.vue"),
    },
    {
      path: "tramites/comunicacion/:id_solicitud_tramite",
      name: "senasag.comunicacion.tramites.curso",
      meta: {
        title: "Comunicación interna y externa",
      },
      component: () => import("./views/ComunicacionSenasag.vue"),
    },
    {
      path: "tramites/pre-solicitados/:requestId",
      name: "senasag.detalle.solicitudes.tramite",
      meta: {
        title: "Detalle trámite pre-solicitados",
      },
      component: () =>
        import("./views/approve-requests/TramitePreSolicitudDetalle.vue"),
    },
    {
      path: "empresas/reportes",
      name: "senasag.reportes.solicitudes.empresa",
      meta: {
        title: "Reportes",
      },
      component: () => import("./views/reportes/ReportesEmpresas.vue"),
    },
    {
      path: "servicios-extras/reportes",
      name: "senasag.reportes.servicios.extras",
      meta: {
        title: "Reportes",
      },
      component: () => import("./views/reportes/ReporteServiciosExtras.vue"),
    },
    {
      path: "economico/reportes",
      name: "senasag.reportes.economico",
      meta: {
        title: "Reportes",
      },
      component: () => import("./views/reportes/ReportesEconomico.vue"),
    },

    {
      path: "economico-productos/reportes/SA",
      name: "senasag.reportes.economico.producto",
      meta: {
        title: "Reportes",
      },
      component: () => import("./views/reportes/ReportesEconomicoProductos.vue"),
    },

    {
      path: "libreventa/reportes",
      name: "senasag.reportes.libreventa",
      meta: {
        title: "Reportes",
      },
      component: () => import("./views/reportes/ReportesLibreVenta.vue"),
    },

    {
      path: "productos/reportes/IA",
      name: "senasag.reportes.productos.ia",
      meta: {
        title: "Reportes Productos IA",
      },
      component: () => import("./views/reportes/IAReport.vue"),
    },
    {
      path: "productos/reportes/SV",
      name: "senasag.reportes.productos.sv",
      meta: {
        title: "Reportes Productos SV",
      },
      component: () => import("./views/reportes/SVReport.vue"),
    },
    {
      path: "productos/reportes/SA",
      name: "senasag.reportes.productos.sa",
      meta: {
        title: "Reportes Productos SA",
      },
      component: () => import("./views/reportes/SAReport.vue"),
    },
    {
      path: "productos/reportes/PETPCR",
      name: "senasag.reportes.productos.petpcr",
      meta: {
        title: "Reportes Productos PETPCR",
      },
      component: () => import("./views/reportes/PetPcrReport.vue"),
    },
    /*add Guiver 02/02/2024*/
    
    {
      path: "productos/reportes/PUV",
      name: "senasag.reportes.productos.puv",
      meta: {
        title: "Reportes Productos PUV",
      },
      component: () => import("./views/reportes/PuvReport.vue"),
    },
    /*end add*/
    {
        path: "auditoria/reportes",
        name: "senasag.reportes.auditoria",
        meta: {
            title: "Reportes Auditoria",
        },
        component: () => import("./views/reportes/ReportesAdministrativo.vue"),
    },
    {
      path: "inspeccion/reportes",
      name: "senasag.reportes.inspeccion",
      meta: {
        title: "Reportes Inpecciones",
      },
      component: () => import("./views/reportes/ReportesInspeccion.vue"),
    },
    //IFF reesporte traslado
    {
      path: "traslado/reportes",
      name: "senasag.reportes.traslado",
      meta: {
        title: "Reporte Traslado",
      },
      component: () => import("./views/reportes/ReporteTraslado.vue"),
    },
    {
      path: "economico/traslado/reportes",
      name: "senasag.reportes.traslado.economico",
      meta: {
        title: "Reporte Económico de Traslado",
      },
      component: () => import("./views/reportes/ReporteEconomicoTraslado.vue"),
    },
    //MFG Reporte de vehiculos
    {
      path: "vehiculos/reportes",
      name: "senasag.reportes.vehiculos",
      meta: {
        title: "Reporte Vehiculos",
      },
      component: () => import("./views/reportes/ReportesVehiculos.vue"),
    },
  ],
  meta: {
    beforeResolve(routeTo, routeFrom, next) {
      if (store.getters["authfack/isSenasagUser"]) return next();
      if (
        store.getters["authfack/isExternalUser"] &&
        store.getters["authfack/isCompanyUser"]
      )
        return next({ name: "empresa.default" });
      if (
        store.getters["authfack/isExternalUser"] &&
        store.getters["authfack/isProcessorUser"]
      )
        return next({ name: "tramitador.default" });
      console.log("You are neither external user nor sia user");
      return false;
    },
  },
};

export default (router) => {
  router.addRoute(moduleRoute);
};
